/* eslint-disable max-lines */
import { useState, useRef } from 'react'
import {
  Button,
  View,
  Text,
  logger,
  Settings,
  Theme,
  variantProvider,
} from '@/app'
import {
  Page,
  CardComponent,
  NewSearchModal,
  SavedSearchesModal,
  NoItems,
  CategoriesModal,
  MyLocationsModal,
  DateModal,
  TimeModal,
} from '@/shared'
import { ExploreMap } from '@/layout/Explore'
import {
  useAppSelector,
  OptionsToQueryString,
  QueryToCategorySelect,
} from '@/lib'
import {
  onUpdate,
  useComponentStyle,
  useSearchParams,
} from '@codeleap/common'
import { usePosition } from '@/lib/hooks'
import { useListings, useProfiles } from '@/services/api'
import { ExploreSkeleton } from '@/skeletons'
import { GetGeocodeName, geocodeLatLng } from '@/lib/utils/GetGeocodeName'
import { formatCenter } from '@/lib/utils/helpers'
import * as geolib from 'geolib'

const IndexPage: React.FC = ({ location }) => {
  const {
    data: { listings },
    queries: Listings,
    loading,
  } = useListings()

  const {
    data: { savedFilters, locations },
    queries: Profiles,
  } = useProfiles()

  const { categories, options } = useAppSelector((store) => store.Categories)
  const { profile, appMounted, isLoggedIn } = useAppSelector((store) => store.Session)
  const { locations: profileLocations } = useAppSelector((store) => store.Locations)
  const { location: ipLocation } = useAppSelector((store) => store.Location)

  const { latitude, longitude, geoStatus } = usePosition(true)

  const mapBoundsRef = useRef()
  const circlesRef = useRef()
  const mapRef = useRef(null)
  const mapsRef = useRef(null)

  type ParamsProps = {
    distance: number
    lat: number
    lng: number
    description: string
  }

  const [params, setParams] = useSearchParams<ParamsProps>({
    zoom: 16,
  })
  const [hoveredItem, setHoveredItem] = useState(null)
  const [mobileListing, setMobileListing] = useState(false)
  const [conditions, setConditions] = useState({})
  const [generated, setGenerated] = useState(null)
  const [circles, setCircles] = useState([])
  const [mapState, setMapState] = useState(null)

  logger.log({ circles, mapState, profileLocations: locations })

  const styles = useComponentStyle(componentStyles)

  const isSmall = Theme.hooks.down('mid')
  const query = OptionsToQueryString(conditions)
  const queryObj = Object.fromEntries(new URLSearchParams(query))

  const profileCoords = profile?.data?.mainLocation?.coords

  onUpdate(() => {
    const generatedVal = QueryToCategorySelect(
      location.search,
      options,
      categories,
    )

    if (options.length && categories.length) {
      if (location.search) {
        setGenerated(generatedVal)
      } else {
        setGenerated({})
      }
    }
  }, [categories, options])

  onUpdate(() => {
    if (appMounted && geoStatus !== 'pending' && !params.lat) {
      if (geoStatus === 'success') {
        updateParams(latitude, longitude)
      } else if (profileCoords?.lat) {
        updateParams(profileCoords.lat, profileCoords.lng)
      } else {
        updateParams(ipLocation?.latitude, ipLocation?.longitude)
      }
    }
  }, [isLoggedIn, geoStatus, ipLocation])

  onUpdate(() => {
    if (isLoggedIn) {
      Profiles.getSavedFilters.send({})
    }
  }, [isLoggedIn])

  onUpdate(() => {
    if (!loading && appMounted && mapBoundsRef.current) handleGetListings()
  }, [params])

  onUpdate(() => {
    if (appMounted) {
      setParams((prev) => {
        return {
          lat: prev.lat,
          lng: prev.lng,
          zoom: prev.zoom,
          description: prev.description,
          title: prev.title,
          ...queryObj,
        }
      })
    }
  }, [conditions])

  const updateParams = (latNum, lngNum, desc = '') => {
    setParams((prev) => {
      return {
        ...prev,
        lat: latNum,
        lng: lngNum,
        description: desc,
      }
    })
  }

  const handleGetListings = () => {

    const paramsWithoutDescription = { ...params }

    const distance = geolib.getDistance(mapBoundsRef?.current?.se, mapBoundsRef?.current?.nw)

    const distanceInUnits = geolib.convertDistance(distance, 'km')

    paramsWithoutDescription.distance = distanceInUnits
    delete paramsWithoutDescription.description
    delete paramsWithoutDescription.zoom
    setTimeout(() => {
      Listings.query.send(paramsWithoutDescription)
    }, 400)
  }

  const handleChange = ({ bounds, center }) => {
    const se = { lat: bounds.se.lat, lng: bounds.se.lng }
    const nw = { lat: bounds.nw.lat, lng: bounds.nw.lng }

    mapBoundsRef.current = { nw, se }

    if (!loading) {
      // const geocoder = mapsRef?.current?.Geocoder()
      // const mapp = mapsRef?.current?.Map()
      // logger.log({ geocoder, mapp })

      GetGeocodeName(formatCenter(params), (description: string) => {
        updateParams(center.lat, center.lng, description)
      })

    }
  }

  return (
    <Page
      center={false}
      showCategories
      hideFooter
      fullWidth={true}
      filterProps={{
        setConditions,
        generated,
        setParams,
        setGenerated,
        mapRef,
        circles,
      }}
      headerInputProps={{
        params,
        setParams,
        isExplore: true,
        loading,
      }}
      title='Explore'
    >
      <View variants={['column', 'fullWidth', 'marginVertical:2']} responsiveVariants={{
        mid: ['marginVertical:0'],
      }}>
        <View css={styles.wrapper} variants={['fullWidth']}>
          <View
            variants={[
              listings.results.length <= 0 && !loading && 'justifyCenter',
              listings.results.length <= 0 && 'alignCenter',
            ]}
            css={[
              styles.innerWrapper,
              styles.listingMarginTop,
              listings.results.length <= 0 &&
                !loading &&
                styles.listingMarginZero,
              mobileListing && isSmall && styles.innerWrapperActive,
              !mobileListing && isSmall && styles.innerWrapperDisabled,
            ]}
          >
            <ExploreSkeleton ready={!loading}>
              {listings.results.length > 0 && (
                <Text
                  text={`${listings.results.length} results found in ${params?.description}`}
                  variants={['primaryColor', 'marginTop:1', 'marginBottom:2']}
                />
              )}
              {listings.results.map((listing, index) => (
                <CardComponent
                  key={index}
                  isHorizontal
                  isHovered={hoveredItem?.id === listing.id}
                  isCardMobileListing={mobileListing && isSmall}
                  listing={listing}
                  onMouseEnter={() => {
                    setHoveredItem(listing)
                  }}
                  onMouseLeave={() => {
                    setHoveredItem(null)
                  }}
                />
              ))}
              {listings.results.length === 0 && (
                <NoItems
                  icon={'noResults'}
                  title={'No Results found'}
                  subtitle={
                    'Sorry, there are no results for this search. Please try another phrase.'
                  }
                  wrapperStyle={styles.noItemsWrapper}
                />
              )}
            </ExploreSkeleton>
          </View>
          <ExploreMap
            onChange={handleChange}
            listings={listings}
            setHoveredItem={setHoveredItem}
            hoveredItem={hoveredItem}
            loading={loading}
            setParams={setParams}
            params={params}
            // resetBoundsOnResize={true}
            mobileListing={mobileListing}
            locations={locations}
            mapBoundsRef={mapBoundsRef}
            setCircles={setCircles}
            mapState={mapState}
            setMapState={setMapState}
            locations={profileLocations}
            mapRef={mapRef}
            mapsRef={mapsRef}

          />
          {isSmall && (
            <View
              variants={['fullWidth', 'justifyCenter', 'fixed']}
              css={styles.toggleMapWrapper}
            >
              <Button
                text={'Map'}
                onPress={() => {
                  setMobileListing(false)
                }}
                variants={['mapMobileRight', mobileListing && 'mapMobileDisabled']}
              />
              <Button
                text={'Listings'}
                onPress={() => {
                  setMobileListing(true)
                }}
                variants={['mapMobileLeft', !mobileListing && 'mapMobileDisabled']}
              />
            </View>
          )}
        </View>
      </View>
      <NewSearchModal
        Profiles={Profiles}
        params={params}
        query={query}
        queryObj={queryObj}
      />
      <SavedSearchesModal
        setParams={setParams}
        setGenerated={setGenerated}
        Profiles={Profiles}
        savedFilters={savedFilters}
        params={params}
      />
      <MyLocationsModal setParams={setParams} />
      <DateModal />
      <TimeModal />
    </Page>
  )
}

export default IndexPage

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  textActive: {
    color: theme.colors.white,
  },
  textDisabled: {
    color: theme.colors.black,
  },
  wrapper: {
    minHeight: '100vh',
    [theme.media.down('mid')]: {
      minHeight: 0,
    },
  },
  toggleMapWrapper: {
    bottom: '5%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  mapButton: {
    borderTopLeftRadius: Theme.spacing.value(1),
    borderBottomLeftRadius: Theme.spacing.value(1),
  },
  listingButton: {
    borderTopRightRadius: Theme.spacing.value(1),
    borderBottomRightRadius: Theme.spacing.value(1),
  },
  buttonActive: {
    backgroundColor: Theme.colors.light.primary,
    border: `${`1px solid ${Theme.colors.primary}`}`,
  },
  buttonDisabled: {
    backgroundColor: Theme.colors.light.white,
    border: `1px solid ${Theme.colors.light.xWhite}`,
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: Theme.spacing.value(2),
    minHeight: '100vh',
    paddingLeft: Theme.spacing.value(2),
    width: 760,
  },
  innerWrapperActive: {
    display: 'flex',
    width: '100%',
    gap: Theme.spacing.value(2),
    overflow: 'hidden',
  },
  innerWrapperDisabled: {
    display: 'none',
  },
  button: {
    width: '30%',
    borderRadius: 0,
  },
  listingMarginTop: {
    marginTop: Theme.spacing.value(16.7),
  },
  listingMarginZero: {
    marginTop: 0,
  },
  noItemsWrapper: {
    [Theme.media.down('mid')]: {
      width: '100%',
      maxWidth: '90%',
    },
  },
}))
